/* While PX, EM, and REM are primarily used for font sizing, %, VW, and VH are mostly used for margins, padding, spacing, and widths/heights.

To reiterate, VH stands for “viewport height”, which is the viewable screen’s height. 100VH would represent 100% of the viewport’s height, or the full height of the screen. And of course, VW stands for “viewport width”, which is the viewable screen’s width. 100VW would represent 100% of the viewport’s width, or the full width of the screen. % reflects a percentage of the parent element’s size, regardless of the viewport’s size. */

/* For gradient => https://webgradients.com/ */

* {
  margin: 0px;
}

.App {
  min-height: 100vh;
  background-image: linear-gradient(to top, #50cc7f 0%, #f5d100 100%);
}

.search-form {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.search-bar {
  width: 50%;
  border: none;
  padding: 10px;
  outline: none;
}

.search-button {
  background: lightcoral;
  border: none;
  /* border-radius: 10px; */
  padding: 10px 20px;
  color: white;
}

.recipes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.heading{
  text-align: center;
}