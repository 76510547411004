/* Whatever CSS we write here will only be related to recipe.js */

.recipe {
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgb(71, 71, 71);
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: white;
  align-items: center;
  min-width: 40%;
}

.image{
    border-radius: 25%;
    width: 150px;
    height: 150px;
}